<template>
  <div class="user">
    <div class="user-info">
      <img v-if="userData.wechatUrl" :src="userData.wechatUrl" alt="" class="head-portrait">
      <img v-else src="@/assets/img/head_portrait.svg" alt="" class="head-portrait">
      <h2>{{ userData.name }}</h2>
      <div>{{ orgName }} </div>
    </div>
    <div class="basic-info">
      <van-field
          readonly
          clickable
          :value="userData.name"
          label="姓名"
          right-icon="arrow"
          @click="$router.push({path: '/edit', query: {info: 'name'}})"
      />
      <van-field
          readonly
          clickable
          :value="userData.gender===1?'男':'女'"
          label="性别"
          right-icon="arrow"
          @click="showGender = true"
      />
      <van-popup v-model="showGender" position="bottom">
        <van-picker
            show-toolbar
            :columns="genderData"
            @confirm="changeGender"
            @cancel="showGender = false"
        />
      </van-popup>
      <van-field
          readonly
          clickable
          :value="userData.birthday"
          label="出生日期"
          right-icon="arrow"
          @click="showCalendar = true"
      />
      <van-popup v-model="showCalendar" position="bottom">
        <van-datetime-picker
            v-model="currentDate"
            type="date"
            title="选择出生日期"
            :min-date="minDate"
            :max-date="maxDate"
            :formatter="formatter"
            @confirm="changeBirthday"
        />
      </van-popup>
      <van-field
          readonly
          clickable
          :value="userData.height"
          type="number"
          label="身高(cm)"
          right-icon="arrow"
          @click="$router.push({path: '/edit', query: {info: 'height'}})"
      />
      <van-field
          readonly
          clickable
          :value="userData.weight"
          type="number"
          label="体重(kg)"
          right-icon="arrow"
          @click="$router.push({path: '/edit', query: {info: 'weight'}})"
      />
      <van-field
          readonly
          clickable
          :value="userData.idCard"
          label="身份证"
          right-icon="arrow"
          @click="$router.push({path: '/edit', query: {info: 'idCard'}})"
      />
      <div>
      <img src="@/assets/img/wtoagzh.png" class="noData">
       <p class="ins">扫码关注公众号</p>
      </div>
      <!-- <van-field
          readonly
          clickable
          name="picker"
          :value="orgName"
          label="机构详情"
          right-icon="arrow"
   
          @click="$router.push({path: '/edit', query: {info: 'orgName'}})" -->
      <!-- /> -->
      <!-- <van-popup v-model="showOrgId" position="bottom"  > -->
        <!-- <van-picker
            show-toolbar
            :columns="orgData"
            @confirm="changeOrgId"
            @cancel="showOrgId = false"
        /> -->
      <!-- </van-popup> -->
    </div>
  </div>
</template>
<script>
import { partialUpdateUser,orgAll,getUser } from "@/api/getData"

export default {
  name: "User",
  data() {
    return {
      orgData:[],
      userData:{},
      orgName: '',
      showGender: false,
      showCalendar: false,
      showOrgId: false,
      currentDate: '',
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      genderData: [
        {
          text: '男',
          value: 1
        },
        {
          text: '女',
          value: 0
        }
      ],
    }
  },
  methods: {
    //orgAll 所有机构数据
    orgAll() {
      orgAll().then(res => {
        this.orgData = res.data.map(item => {
          return {text: item.name, value: item.id}
        })
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].id === this.userData.orgId) {
            this.orgName = res.data[i].name
            return
          }
        }
      })
    },
    //获取用户数据
   getUser() {
      getUser(this.vUserId).then(res => {
        this.userData = res.data
        this.currentDate = new Date(this.userData.birthday)
        this.orgAll()
      })
    },
    //选择性别
    changeGender(data) {
      this.userData.gender = data.value
      this.updateUser()
      this.showGender = false
    },
    //日期格式化处理
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`
      }
    },
    //选择出生日期
    changeBirthday(date) {
      let M = date.getMonth() + 1, d = date.getDate()
      M = M < 10 ? '0' + M : M
      d = d < 10 ? '0' + d : d
      this.userData.birthday = `${date.getFullYear()}-${M}-${d}`
      this.updateUser()
      this.showCalendar = false
    },
    //选择所属机构
    changeOrgId(data) {
      this.userData.orgId = data.value
      for (var i = 0; i < this.orgData.length; i++) {
        if (this.orgData[i].value === this.userData.orgId) {
          this.orgName = this.orgData[i].text
          this.updateUser()
          this.showOrgId = false
          return
        }
      }
    },
    //更新个人信息
    updateUser() {         
      partialUpdateUser(this.vUserId,this.userData).then(res => {
        this.$message.success('信息更新成功')
      }) 
    },
  },
  mounted() {
    this.getUser()
  }
}
</script>

<style lang="scss" scoped>
.user {
  padding: 1.18rem 0 0.58rem;
  background: url(../../assets/img/bg_user.png) no-repeat;
  background-size: 100%;

  .noData{
    display: block;
    width: 100%;
    margin: 0 auto;
    width:2rem;
  }
  .ins{
    color: #9BA5A5;
    font-size: 0.14rem;
    line-height: 0.24rem;
    text-align: center;
  }
  .user-info {
    position: absolute;
    top: 0.56rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 1;

    .head-portrait {
      width: 1.24rem;
      border-radius: 50%;
    }

    h2 {
      height: 0.2rem;
      font-size: 0.2rem;
      font-weight: 500;
      line-height: 1;
      margin: 0.16rem 0;
      color: #333333;
    }

    div {
      font-size: 0.1rem;
      color: rgba(51, 51, 51, 0.9);
    }
  }

  .basic-info {
    border-radius: 0.08rem 0.08rem 0 0;
    padding: 1.46rem 0 0.26rem;
    background-color: #fff;

    > :deep(.van-cell) {
      font-size: 0.14rem;
      height: 0.56rem;
      line-height: 0.56rem;
      padding: 0.12rem 0;

      &::after {
        left: 0;
        right: 0;
      }

      .van-field__label {
        width: 0.6rem;
        line-height: 0.32rem;
        margin: 0 0.32rem 0 0.16rem;
        color: #323233;
      }
  .erwie{
    width: 1rem;
    height: 1rem;
  }
      .van-field__control {
        width: 2.25rem;
        height: 0.32rem;
        line-height: 0.32rem;
      }

      .van-field__right-icon {
        width: 0.3rem;
        height: 0.3rem;
        padding: 0;
        text-align: center;
        line-height: 0.3rem;

        .van-icon {
          font-size: 0.16rem;
        }
      }
    }

    > .van-cell:last-child::after {
      display: block;
    }
  }
}
</style>